@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
  margin-bottom: 24px;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
  font-weight: 500;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.primaryButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 41px;
  letter-spacing: 0;

  font-weight: var(--fontWeightMedium);

  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 0 20px 20px 20px;
  width: auto;

  @media (--viewportMedium) {
    margin: 10px 0;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  /* Mobile Safari couldn't handle width: calc(100vw - 48px); */
  width: calc(100vw - 48px);
  margin: 0 24px;

  /* Gutter between inputs (when calendar is not visible) */
  & .startDateLabel,
  & .endDateLabel {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
  }

  & .input {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
    transition: all 0.15s ease-out;
  }

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;

    /* Gutter between inputs (when calendar is not visible) */
    & .startDateLabel,
    & .endDateLabel {
      flex-basis: calc(50% - 12px);
    }

    & .input {
      flex-basis: calc(50% - 12px);
      transition: all 0.15s ease-out;
    }
  }
}

.infoBoxWrapper {
  margin: 20px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionOpeningHoursMaybe {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  @media (--viewportMedium) {
    display: none;
  }
}

.customerInitiatedCheckbox {
  margin: 20px;

  @media (--viewportMedium) {
    margin: 0 0 20px 0;
  }
}

.customerInitiatedText {
  composes: marketplaceDefaultFontStyles from global;
}
