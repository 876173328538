@import '../../styles/customMediaQueries.css';

.heading {
  composes: h2 from global;
  margin-top: 0;
}
.subheading {
  composes: h3 from global;
  margin-top: 0;
}

.bold {
  font-weight: bolder;
}

.safeHiringTipsContent {
  background-color: var(--matterColorLight);
  border: 1px var(--matterColorNegative) solid;
  max-width: 1128px;
  margin: 0 auto 57px auto;
  padding: 36px;
}

.list {
  padding-left: 2rem;
  list-style-type: disc;
}

.list li {
  margin-bottom: 0.5rem;
}
